import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  
    {
        path: '/operadores/list',
        title: 'Operadores',
        icon: 'Sliders',
        class: '',
        extralink: false,
        label: '',
        labelClass: '',
        submenu: []
    },
    {
        path: '/usuarios/list',
        title: 'Usuarios',
        icon: 'Users',
        class: '',
        extralink: false,
        label: '',
        labelClass: '',
        submenu: []
    },
    {
        path: '/empresas/list',
        title: 'Empresas',
        icon: 'fas fa-industry',
        class: '',
        extralink: false,
        label: '',
        labelClass: '',
        submenu: []
    },
    {
        path: '/delegaciones/list',
        title: 'Delegaciones',
        icon: 'fas fa-home',
        class: '',
        extralink: false,
        label: '',
        labelClass: '',
        submenu: []
    },
    {
        path: '/estadosleads/list',
        title: 'Estados Leads',
        icon: 'fas fa-eye',
        class: '',
        extralink: false,
        label: '',
        labelClass: '',
        submenu: []
    },
    {
        path: '/companies/list',
        title: 'Compañías',
        icon: 'fas fa-home',
        class: '',
        extralink: false,
        label: '',
        labelClass: '',
        submenu: []
    },
    {
        path: '/permisos/list',
        title: 'Permisos',
        icon: 'fas fa-key',
        class: 'has-arrow',
        extralink: false,
        label: '',
        labelClass: '',
        submenu: [
            {
                path: '/permisos/list',
                title: 'Permisos',
                icon: 'fas fa-key',
                class: '',
                extralink: false,
                label: '',
                labelClass: '',
                submenu: []
            },
            {
                path: '/permisos/roles',
                title: 'Roles',
                icon: 'fas fa-align-justify',
                class: '',
                extralink: false,
                label: '',
                labelClass: '',
                submenu: []
            },
            {
                path: '/permisos/users',
                title: 'Usuarios',
                icon: 'fas fa-user',
                class: '',
                extralink: false,
                label: '',
                labelClass: '',
                submenu: []
            },
        ]
    },
    {
        path: '/comerciales/list',
        title: 'Comerciales',
        icon: 'fas fa-briefcase',
        class: '',
        extralink: false,
        label: '',
        labelClass: '',
        submenu: []
    },
    {
        path: '/backoffices/list',
        title: 'Backoffices',
        icon: 'fas fa-briefcase',
        class: '',
        extralink: false,
        label: '',
        labelClass: '',
        submenu: []
    },
    {
        path: '/leads/list',
        title: 'Leads',
        icon: 'fas fa-users',
        class: 'has-arrow',
        extralink: false,
        label: '',
        labelClass: '',
        submenu: [
            {
                path: '/leads/list',
                title: 'Leads',
                icon: 'fas fa-users',
                class: '',
                extralink: false,
                label: '',
                labelClass: '',
                submenu: []
            },
            {
                path: '/campaigns/list',
                title: 'Campañas captación',
                icon: 'fas fa-key',
                class: '',
                extralink: false,
                label: '',
                labelClass: '',
                submenu: []
            },
            {
                path: '/leads/importar',
                title: 'Importar CSV',
                icon: 'fas fa-upload',
                class: '',
                extralink: false,
                label: '',
                labelClass: '',
                submenu: []
            },
            {
                path: '/leads/asignacion',
                title: 'Asignar Leads',
                icon: 'fas fa-tasks',
                class: '',
                extralink: false,
                label: '',
                labelClass: '',
                submenu: []
            },
        ]
    },
    {
        path: '/equipos/list',
        title: 'Equipos',
        icon: 'fas fa-users',
        class: '',
        extralink: false,
        label: '',
        labelClass: '',
        submenu: []
    },
    {
        path: '/contratos/list',
        title: 'Contratos',
        icon: 'Sliders',
        class: '',
        extralink: false,
        label: '',
        labelClass: '',
        submenu: []
    },
];
