<div *ngIf="!logged" class="account-pages">
  <!-- Begin page -->
  <div *ngIf="allowed" class="wrapper-page account-page-full">
    <div class="card">
      <div class="card-block">
        <div class="account-box">
          <div class="card-box p-5">
            <h2 class="text-uppercase text-center pb-4">
              <span><img [src]="logo" alt="{{empresa.nombre}}"></span>
            </h2>
            <form class="" [formGroup]="formLogin">
              <div class="form-group m-b-20 row">
                <div class="col-12">
                  <label for="dni">CIF / NIF / NIE</label>
                  <input class="form-control" type="text" id="usuario" required="" placeholder="Introduce tu numero de documento"
                  formControlName="dni" name="dni">
                </div>
              </div>
              <div class="form-group row m-b-20">
                <div class="col-12">
                  <!-- <a href="page-recoverpw.html" class="text-muted float-right"><small>Forgot your password?</small></a> -->
                  <label for="pin">PIN</label>
                  <input class="form-control" type="text" required="" id="pin" placeholder="Introduce tu PIN"
                   formControlName="pin" name="pin">
                </div>
              </div>
              <div class="form-group row text-center m-t-50">
                <div class="col-12">
                  <button type="submit" class="btn btn-primary btn-block color-white" (click)="login(formLogin.value)">Entrar</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="loading" class="popup">
  <div class="content">
      <div class="spinner"><span class="fas fa-spinner fa-pulse"></span></div>
     <div class="text">Cargando ...</div> 
  </div>
</div>
<div *ngIf="logged" class="contract">
  <div class="container">
    <img class="logo" [src]="logo" alt="{{empresa.nombre}}" >
    <div class="wrapper">
      <h1>Firma de contratos YOIGO</h1>
      <h4>Repasa los datos de tu contrato antes de firmar. Al final del formulario tendrás el botón y espacio para firmar.</h4>
      <div class="card-block">
        <p class="card-title">Datos del contratante</p>
        <div class="card-body">
          <p class="card-line"><span>Nombre o razón social:</span>{{contrato?.name}}</p>
          <p *ngIf="contrato?.tipo_contrato === 0 || contrato?.tipo_empresa === 0" class="card-line"><span>CIF / NIF / NIE:</span>{{contrato?.cliente.num_documento}}</p>
          <p *ngIf="contrato?.tipo_contrato === 1 && contrato?.tipo_empresa === 1" class="card-line">
            <span>CIF / NIF / NIE:</span>{{contrato?.cliente.num_documento}}
            <span class="continue">Fecha constitución:</span>{{contrato?.cliente.constitucion | date: 'dd-MM-yyyy'}}
          </p>
          <p class="card-line"><span>Dirección:</span>{{contrato?.cliente.domicilio}}</p>
          <p class="card-line">
            <span>CP:</span>{{contrato?.cliente.cp}}
            <span class="continue">Localidad:</span>{{contrato?.cliente.localidad.nombre}}
            <span class="continue">Provincia:</span>{{contrato?.cliente.provincia.nombre}}
          </p>
          <p class="card-line">
            <span>Teléfono:</span>{{contrato?.cliente.telefono}}
            <span class="continue">Email:</span>{{contrato?.cliente.email}}
          </p>
          <p class="card-line">
            <span>CCC:</span>{{contrato?.cliente.ccc}}
          </p>
        </div>
      </div>
      <div class="card-block">
        <p class="card-title">Datos de la persona autorizada</p>
        <div class="card-body">
          <p class="card-line"><span>Nombre:</span>{{contrato?.autorizado.nombre}} {{contrato?.autorizado.apellido1}} {{contrato?.autorizado.apellido2}}</p>
          <p  class="card-line"><span>NIF / NIE:</span>{{contrato?.autorizado.num_documento}}</p>
          <p  class="card-line">
            <span>Fecha nacimiento:</span>{{contrato?.autorizado.fecha_nac | date:'dd-MM-yyyy'}}
            <span class="continue">Nacionalidad:</span>{{contrato?.autorizado.nacionalidad}}
          </p>
          <p class="card-line">
            <span>Teléfono:</span>{{contrato?.autorizado.telefono}}
            <span class="continue">Email:</span>{{contrato?.autorizado.email}}
          </p>
        </div>
      </div>
      <div class="card-block">
        <p class="card-title">Datos de la persona de contacto</p>
        <div class="card-body">
          <p class="card-line"><span>Nombre:</span>{{contrato?.contacto.nombre}} {{contrato?.contacto.apellido1}} {{contrato?.contacto.apellido2}}</p>
          <p  class="card-line"><span>NIF / NIE:</span>{{contrato?.contacto.num_documento}}</p>
          <p  class="card-line">
            <span>Fecha nacimiento:</span>{{contrato?.contacto.fecha_nac | date:'dd-MM-yyyy'}}
            <span class="continue">Nacionalidad:</span>{{contrato?.contacto.nacionalidad}}
          </p>
          <p class="card-line">
            <span>Teléfono:</span>{{contrato?.contacto.telefono}}
            <span class="continue">Email:</span>{{contrato?.contacto.email}}
          </p>
        </div>
      </div>
      <div *ngIf="contrato?.fijo" class="card-block">
        <p class="card-title">Datos de la linea fija</p>
        <div class="card-body">
          <p *ngIf="contrato?.fijo.portafijo === 0" class="card-line"><span>Tipo:</span> Alta nueva linea</p>
          <p *ngIf="contrato?.fijo.portafijo === 1" class="card-line"><span>Tipo:</span> Portabilidad</p>
          <p *ngIf="contrato?.fijo.portafijo === 1" class="card-line">
            <span>Número:</span>{{contrato?.fijo.num_porta}}
            <span class="continue">Operador donante:</span>{{contrato?.fijo.operador.nombre}}
          </p>
          <p class="card-line"><span>Tarifa:</span>{{contrato?.fijo.tarifa.nombre}}</p>
          <p *ngIf="contrato?.fijo.tv === 0 && contrato?.fijo.tvgratis === 0" class="card-line"><span>Agile Tv:</span>No</p>
          <p *ngIf="contrato?.fijo.tv === 1" class="card-line"><span>Agile Tv:</span>Si</p>
          <p *ngIf="contrato?.fijo.tvgratis === 1" class="card-line"><span>Agile Tv:</span>Si (Promoción gratis)</p>
          <p class="card-line"><span>Dirección instalación:</span>{{contrato?.fijo.domicilio}}</p>
          <p class="card-line">
            <span>CP:</span>{{contrato?.fijo.cp}}
            <span class="continue">Localidad:</span>{{contrato?.fijo.localidad.nombre}}
            <span class="continue">Provincia:</span>{{contrato?.fijo.provincia.nombre}}
          </p>
          
        </div>
      </div>
      <div *ngIf="contrato?.lineas.length > 0" class="card-block">
        <p class="card-title">Datos de las lineas móviles</p>
        <div class="card-body">
          <ng-container *ngFor="let item of contrato?.lineas; let i = index">
            <p class="card-line"><span>Línea {{i + 1}}</span></p>
            <p class="card-line">
              <span>Movimiento:</span>{{item.movimiento}}
              <span class="continue">Tarifa:</span>{{item.tarifa.nombre}}
            </p>
            <p *ngIf="item.movimiento === 'Portabilidad'" class="card-line">
              <span>Numero:</span>{{item.numero}}
              <span class="continue">Operador donante:</span>{{item.operador.nombre}}
            </p>
            <p *ngIf="item.movimiento !== 'Portabilidad'" class="card-line">
              <span>Numero:</span>{{item.tipo_movimiento}}
            </p>
            <p class="card-line"><span>Sim:</span>{{item.tipo_movimiento}}</p>
            <hr>
          </ng-container>   
        </div>
      </div>
      <div *ngIf="contrato?.terminales.length > 0" class="card-block">
        <p class="card-title">Datos de los terminales</p>
        <div class="card-body">
          <ng-container *ngFor="let item of contrato?.terminales; let i = index">
            <p class="card-line"><span>Terminal {{i + 1}}</span></p>
            <p class="card-line">
              <span>Terminal:</span>{{item.terminal.nombre}}
            </p>
            <p class="card-line">
              <span>Aplazado:</span>{{item.aplazado}}
            </p>
            <p class="card-line">
              <span>Pago inicial:</span>{{item.inicial}} €
              <span class="continue">Pago mes:</span>{{item.pagomes}} €
              <span class="continue">Pago final:</span>{{item.final}} €
            </p>
            <hr>
          </ng-container>   
        </div>
      </div>
    </div>
    <form  class="" [formGroup]="formSign">
      <div class="form-row">
        <div id="signature-pad" style="width: 100%;margin: 50px auto;display: flex;justify-content: center;">
          <signature-pad #signaturePad [options]="signaturePadOptions" (onBeginEvent)="drawBegin()" (onEndEvent)="drawComplete()"></signature-pad>
        </div>
      </div>
      <div class="buttons">
        <a *ngIf="!download" class="btn btn-primary color-white" (click)="deleteSignature()">Borrar firma</a>
        <button *ngIf="!download" class="btn btn-primary btn-block color-white" (click)="sign(formSign.value)" [disabled]="formSign.invalid">Firmar</button>
        <a *ngIf="download" class="btn btn-primary download color-white" (click)="downloadContracts()">Descargar contratos</a>
      </div>
    </form>
  </div>
</div>