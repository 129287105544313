
import { Routes } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';
import { LoginComponent } from './authentication/login/login.component';
import { AuthGuard } from './authentication/guards/auth.guard';
import { SignComponent } from './sign/sign.component';
import { AutosignComponent } from './autosign/autosign.component';

export const Approutes: Routes = [
    {
        path: '',
        
        component: FullComponent,
        canActivate: [AuthGuard],
        children: [
            { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
            {
                path: 'dashboard',
                loadChildren: () => import('./dashboards/dashboard.module').then(m => m.DashboardModule)
            },
            {
                path: 'starter',
                loadChildren: () => import('./starter/starter.module').then(m => m.StarterModule)
            },
            {
                path: 'component',
                loadChildren: () => import('./component/component.module').then(m => m.ComponentsModule)
            },
            { path: 'cards', loadChildren: () => import('./cards/cards.module').then(m => m.CardsModule) },
            { path: 'icons', loadChildren: () => import('./icons/icons.module').then(m => m.IconsModule) },
            { path: 'forms', loadChildren: () => import('./form/forms.module').then(m => m.FormModule) },
            { path: 'tables', loadChildren: () => import('./table/tables.module').then(m => m.TablesModule) },
            { path: 'charts', loadChildren: () => import('./charts/charts.module').then(m => m.ChartModule) },
            {
                path: 'widgets',
                loadChildren: () => import('./widgets/widgets.module').then(m => m.WidgetsModule)
            },
            { path: 'ecom', loadChildren: () => import('./ecommerce/ecom.module').then(m => m.EcomModule) },
            {
                path: 'timeline',
                loadChildren: () => import('./timeline/timeline.module').then(m => m.TimelineModule)
            },
            {
                path: 'extra-component',
                loadChildren:
                    () => import('./extra-component/extra-component.module').then(m => m.ExtraComponentModule)
            },
            { path: 'usuarios', loadChildren: () => import('./usuarios/usuarios.module').then(m => m.UsuariosModule) },
            { path: 'operadores', loadChildren: () => import('./operadores/operadores.module').then(m => m.OperadoresModule) },
            { path: 'empresas', loadChildren: () => import('./empresas/empresas.module').then(m => m.EmpresasModule) },
            { path: 'delegaciones', loadChildren: () => import('./delegaciones/delegaciones.module').then(m => m.DelegacionesModule) },
            { path: 'estadosleads', loadChildren: () => import('./estadosleads/estadosleads.module').then(m => m.EstadosleadsModule) },
            { path: 'permisos', loadChildren: () => import('./permisos/permisos.module').then(m => m.PermisosModule) },
            { path: 'comerciales', loadChildren: () => import('./comerciales/comerciales.module').then(m => m.ComercialesModule) },
            { path: 'backoffices', loadChildren: () => import('./backoffices/backoffices.module').then(m => m.BackofficesModule) },
            { path: 'companies', loadChildren: () => import('./companies/companies.module').then(m => m.CompaniesModule) },
            { path: 'campaigns', loadChildren: () => import('./campaigns/campaigns.module').then(m => m.CampaignsModule) },
            { path: 'equipos', loadChildren: () => import('./equipos/equipos.module').then(m => m.EquiposModule) },
            { path: 'leads', loadChildren: () => import('./leads/leads.module').then(m => m.LeadsModule) },
            { path: 'apps', loadChildren: () => import('./apps/apps.module').then(m => m.AppsModule) },
            { path: 'contratos', loadChildren: () => import('./contratos/contratos.module').then(m => m.ContratosModule) },

            { path: 'apps/email', loadChildren: () => import('./apps/email/mail.module').then(m => m.MailModule) },

            { path: 'maps', loadChildren: () => import('./maps/maps.module').then(m => m.MapsModule) },
            {
                path: 'sample-pages',
                loadChildren: () => import('./sample-pages/sample-pages.module').then(m => m.SamplePagesModule)
            }
        ]
    },
    {
        path: '',
        component: BlankComponent,
        children: [
            {
                path: 'authentication',
                loadChildren:
                    () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
            }
        ]
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'sign-contract',
        component: SignComponent,
      },
      {
        path: 'autosign-contract',
        component: AutosignComponent,
      },
    {
        path: '**',
        redirectTo: '/authentication/404'
    }
];